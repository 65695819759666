enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  PARSE = "parse",
  UPDATE_ACTIVE_ACCOUNT_INFO = "updateActiveAccountInfo"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_ACTIVE_ACCOUNT = "setActiveAccount",
  UPDATE_ACTIVE_ACCOUNT = "updateActiveAccount",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  GET_USER_INFO_STORAGE_KEY = "getUserInfoStorageKey",
  GET_USER_INFO = "getUserInfo",
  GET_SESSION = "getSession",
  SET_ENTRY_INFO_ACCOUNT_ID = "setEntryInfoAccountId",
  UPDATE_ACTIVE_ACCOUNT_DETAIL = "updateActiveAccountDetail",
  UPDATE_ACTIVE_ACCOUNT_RELATIONSHIP = "updateActiveAccountRelationship"
}

export { Actions, Mutations };
